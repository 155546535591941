<template>
  <div class="login-page">
    <div class="lang-box">
      <el-dropdown trigger="click">
        <div class="lang-info flex-center">
          {{ langName }}
          <img :src="langIcon" alt="" />
          <i
            class="el-icon-caret-bottom"
            style="color: #fff; font-size: 14px"
          ></i>
        </div>
        <el-dropdown-menu slot="dropdown" class="lang-dropdown">
          <el-dropdown-item v-for="(item, i) in langList" :key="i">
            <div class="flex-center-start lang-menu" @click="changeLang(item)">
              <img :src="item.icon" alt="" />
              <span>{{ item.name }}</span>
            </div>
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
    <div class="form-main flex-column-center">
      <div class="logo">
        <img :src="logo || logoUrl" alt="" />
      </div>
      <div class="title">{{ $t('deng-lu-dao-nin-de-zhang-hu') }}</div>
      <div class="tabs flex">
        <div
          class="tab"
          :class="tabIndex == 1 ? 'active' : ''"
          @click="changeTab(1)"
        >
          {{ $t('you-xiang-0') }}
        </div>
        <div
          class="tab"
          :class="tabIndex == 2 ? 'active' : ''"
          @click="changeTab(2)"
        >
          {{ $t('shou-ji-hao') }}
        </div>
      </div>
      <div class="login-form">
        <el-form :model="form" ref="form" :rules="formRules">
          <el-form-item prop="email" v-if="tabIndex == 1">
            <el-input
              v-model="form.email"
              class="form-input"
              :placeholder="$t('qing-shu-ru-you-xiang-5')"
              clearable
              prefix-icon="el-icon-message"
            ></el-input>
          </el-form-item>
          <el-form-item prop="phone" v-if="tabIndex == 2">
            <el-input
              class="phone-input form-input big"
              v-model="form.phone"
              clearable
              :placeholder="$t('qing-shu-ru-shou-ji-hao-8')"
            >
              <template slot="prefix">
                <div class="flex-center-start phone-pre">
                  <i
                    class="el-icon-mobile-phone icon"
                    style="color: #fff; font-size: 16px"
                  ></i>
                  <el-dropdown
                    placement="bottom-start"
                    @command="chooseCountry"
                    class="phone-pre-dropdown"
                  >
                    <span class="text flex-center">
                      {{ form.phonePre }}
                      <i class="el-icon-arrow-down"></i>
                    </span>
                    <el-dropdown-menu slot="dropdown">
                      <div class="phone-dropdown-item">
                        <el-input
                          v-model="keyword"
                          :placeholder="$t('sou-suo-2')"
                          @input="searchCountry"
                          @blur="searchCountry"
                        ></el-input>
                        <div
                          v-for="group in showCountryList"
                          :key="group.id"
                          :label="group.title"
                        >
                          <el-dropdown-item
                            v-for="item in group.children"
                            :key="item.key"
                            :command="item.key"
                            >{{ item.title }}</el-dropdown-item
                          >
                        </div>
                      </div>
                    </el-dropdown-menu>
                  </el-dropdown>
                </div>
              </template>
            </el-input>
          </el-form-item>
          <el-form-item prop="password">
            <el-input
              :type="showPassword ? 'text' : 'password'"
              v-model="form.password"
              class="form-input"
              prefix-icon="el-icon-lock"
            >
              <div
                slot="suffix"
                class="icon-eye flex-center"
                @click="showPassword = !showPassword"
              >
                <img :src="eyeUrl" alt="" v-if="!showPassword" />
                <img :src="eyeUrl2" alt="" v-else />
              </div>
            </el-input>
          </el-form-item>
        </el-form>

        <div class="form-info flex-center-between">
          <div>
            {{ $t('ru-guo-nin-mei-you-zhang-hao') }}
            <span class="blue" @click="toPath('/register')">{{
              $t('dian-ji-zhu-ce')
            }}</span>
          </div>
          <div class="blue">{{ $t('wang-ji-mi-ma-0') }}</div>
        </div>

        <div class="form-btn">
          <el-button type="primary" @click="submit">{{
            $t('deng-lu-0')
          }}</el-button>
        </div>
      </div>
    </div>
    <div class="kefu-box">
      <div class="message-icon flex-center" @click="showChat">
        <i
          class="el-icon-chat-dot-round"
          style="color: #fff; font-size: 24px"
        ></i>
      </div>
      <div class="kefu-icon flex-center" @click="toKefu">
        <i class="el-icon-service" style="color: #fff; font-size: 24px"></i>
      </div>
    </div>
  </div>
</template>
<script>
import { login } from '@/api/login'
import { setToken, setUser, setUserId, setUserType } from '@/utils/auth'
export default {
  components: {},
  data() {
    return {
      logoUrl: require('../../assets/imgs/logo.png'),
      eyeUrl: require('../../assets/imgs/icon-eye.png'),
      eyeUrl2: require('../../assets/imgs/icon-eye2.png'),
      showPassword: false,
      checked: false,
      showBottomText: true,
      form: {
        email: '',
        phone: '',
        phonePre: '+86',
        password: '',
        Fingerprint: '',
        code: ''
      },
      formRules: {
        phone: [
          { required: true, message: this.$t('qing-shu-ru-shou-ji-hao-1') }
        ],
        email: [
          { required: true, message: this.$t('qing-shu-ru-you-xiang-0') }
        ],
        password: [{ required: true, message: this.$t('qing-shu-ru-mi-ma-0') }]
      },
      checked: false,
      initialHeight: window.innerHeight,
      showLang: false,
      tabIndex: 1,
      keyword: '',
      showCountryList: []
    }
  },
  computed: {
    logo() {
      return this.$store.state.logo
    },
    visitor_service() {
      return this.$store.state.visitor_service
    },
    langName() {
      return this.$store.state.langName
    },
    langIcon() {
      return this.$store.state.langIcon
    },
    langList() {
      return this.$store.state.langList
    },
    lang() {
      return this.$store.state.lang
    },
    countryList() {
      return this.$store.state.countryList
    }
  },
  mounted() {
    this.initLang()
    this.showCountryList = [].concat(this.countryList)
  },
  methods: {
    initLang() {
      let index = this.langList.findIndex((v) => {
        return this.lang == v.key
      })
      if (index > -1) {
        this.$store.state.langIcon = this.langList[index].icon
        this.$store.state.langName = this.langList[index].name
      }
    },
    toKefu() {
      this.$router.push({
        name: 'kefu'
      })
    },
    toRegister() {
      this.$router.push({
        name: 'register'
      })
    },
    submit() {
      this.$refs.form.validate((vali) => {
        if (vali) {
          login({
            Account:
              this.tabIndex === 1
                ? this.form.email
                : `${this.form.phonePre}${this.form.phone}`,
            Password: this.form.password,
            IsBusiness: 1
          }).then((res) => {
            if (res.code == 0) {
              this.$message.success(this.$t('deng-lu-cheng-gong'))
              let data = res.data
              this.$store.state.userType = data.business
              this.$store.state.isLogin = true
              setUserId(data.id)
              setUserType(data.business)
              setToken(data.token)
              this.$router.push({
                name: data.business == 1 ? 'dashboard' : 'index'
              })
            } else {
              this.$message.warning(res.msg)
            }
          })
        }
      })
    },
    toLang() {
      this.showLang = !this.showLang
    },
    changeLang(data) {
      this.showLang = false
      this.i18n.locale = data.key
      localStorage.setItem('locale', data.key)
      this.$store.state.lang = data.key
      this.$store.state.langIcon = data.icon
      this.$store.state.langName = data.name
    },
    changeTab(index) {
      this.tabIndex = index
      this.$refs.form.resetField()
    },
    searchCountry() {
      if (!this.keyword) {
        this.showCountryList = [].concat(this.countryList)
      } else {
        let allList = JSON.parse(JSON.stringify(this.countryList))
        this.showCountryList = allList.map((v) => {
          v.children = v.children.filter((val) => {
            return (
              val.key.indexOf(this.keyword) > -1 ||
              val.title.toLowerCase().indexOf(this.keyword.toLowerCase()) > -1
            )
          })
          return v
        })
      }
    },
    chooseCountry(data) {
      this.form.phonePre = data
    },
    toPath(path) {
      this.$router.push(path)
    },
    showChat() {
      this.$store.state.showChat = true
    },
    toKefu() {
      this.$router.push({
        name: 'wapKf'
      })
    }
  }
}
</script>